<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">顧客一覧</h3>
      <v-row class="no-gutters mb-4" align="center">
        <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="search"
            @input="getDataFromApi('searched')"
            dense
            outlined
            hide-details
          >
            <v-btn
              slot="append"
              color="blue"
              small
              class="white--text"
              elevation="0"
              height="23"
              @click="getDataFromApi('searched')"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/customer/create"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            顧客を登録する
          </v-btn>
        </v-col>
      </v-row>
      <Table
        :headers="headers"
        :items="sites"
        :total-records="pagination.records_total"
        :number-of-pages="pagination.total_pages"
        :items-per-page="itemsPerPage"
        @click:row="goToDetailSite"
        :footer="false"
        :loading="loading"
        :options.sync="options"
      >
        <template v-slot:item.customer_name="{ item }">
          {{ item.customer ? item.customer.name : '-' }}
        </template>

        <template v-slot:item.customer_id="{ item }">
          {{ item.customer ? item.customer.id : '-' }}
        </template>

        <template v-slot:item.name="{ item }">
          <div class="custom-wdt font-weight-bold">{{ item.site_id }} {{ item.name }}</div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="custom-wdt">
            {{
              item.prefecture || item.city || item.address
                ? (item.prefecture ? item.prefecture.name : '') +
                  (item.city || '') +
                  (item.address || '')
                : '-'
            }}
          </div>
        </template>

        <template v-slot:item.service_type="{ item }">
          {{
            item.projects
              ? item.projects[item.projects.length - 1].service_type
              : null
          }}
        </template>

        <template v-slot:item.latest_task_date="{ item }">
          {{ item.latest_task ? formatDate(item.latest_task.date) : '-' }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            fab
            x-small
            @click="goToDetailSiteWithModal(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import Table from '@/components/admin/partials/Table/Table'
import dayjs from 'dayjs'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      loading: true,
      itemsPerPage: 25,
      headers: [
        {
          text: '物件名',
          align: 'center',
          value: 'name',
          sortable: false
        },
        { text: '顧客ID', align: 'center', value: 'customer_id' },
        {
          text: '顧客名',
          align: 'center',
          value: 'customer_name',
          sortable: false
        },
        {
          text: 'サービス',
          align: 'center',
          value: 'service_type',
          sortable: false
        },
        {
          text: '物件住所',
          align: 'center',
          value: 'address',
          sortable: false
        },
        {
          text: '最終作業日',
          align: 'center',
          value: 'latest_task_date',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      search: '',
      options: {}
    }
  },
  computed: {
    sites() {
      let sites = this.$store.getters.listSites
      return sites
    },
    pagination() {
      return this.$store.getters.sitesPagination
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getDataFromApi: debounce(function(isSearched = null) {
      if(isSearched == 'searched') {
        this.options.page = 1
      }
      this.loading = true
      let params = {
        search: this.search,
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
      this.$store
        .dispatch('SITE_GET_ALL_LIST', params)
        .then(() => (this.loading = false))
    }, 1000),
    goToDetailSite(site) {
      let routeData = this.$router.resolve({
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: site.customer.id,
          site_id: site.id
        }
      })
      window.open(routeData.href, '_self')
    },

    goToDetailSiteWithModal(site) {
      localStorage.setItem('site_edit', 1)
      let modalWithData = this.$router.resolve({
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: site.customer.id,
          site_id: site.id
        }
      })

      window.open(modalWithData.href, '_self')
    },

    formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD')
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
